
import { IonContent, IonPage, IonRow, IonCol} from '@ionic/vue';
import { defineComponent } from 'vue';
import SignUpForm from '@/modules/Daycares/components/SignUpForm.vue';
import MainFeatures from "@/modules/Daycares/components/MainFeatures.vue"
import MainTagline from "@/modules/Daycares/components/MainTagline.vue"

export default defineComponent({
  name: 'DaycaresPage',
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    SignUpForm,
    MainFeatures,
    MainTagline
  },
});
