import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import ParentsPage from '../modules/Parents/Parents.vue'
import DaycaresPage from '../modules/Daycares/Daycares.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/parents'
  },
  {
    path: '/parents',
    component: ParentsPage
  },
  {
    path: '/daycares',
    component: DaycaresPage
  },
  { path: '/:pathMatch(.*)', component: ParentsPage }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
