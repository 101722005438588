
import { IonCol, IonRow, IonHeader, IonToolbar, IonPopover, IonIcon, IonItem} from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapOutline, bookmarkOutline, peopleCircleOutline, homeOutline, mailOutline, helpOutline, menuSharp } from 'ionicons/icons';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HeaderSection',
  components: {
    IonCol,
    IonRow,
    IonHeader,
    IonToolbar, 
    IonPopover,
    IonIcon,
    IonItem
  },
  setup() {
    const router = useRouter();
    return { 
      mapOutline,
      bookmarkOutline,
      peopleCircleOutline,
      homeOutline,
      mailOutline,
      helpOutline,
      menuSharp,
      router
    }
  },
});
