import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainTagline = _resolveComponent("MainTagline")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_SignUpForm = _resolveComponent("SignUpForm")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_MainFeatures = _resolveComponent("MainFeatures")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "main-section" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_MainTagline)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_SignUpForm)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_MainFeatures)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}