
import { IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonInput, IonButton, IonIcon, toastController, IonSelect, IonSelectOption, IonSpinner} from '@ionic/vue';
import { defineComponent } from 'vue';
import { logoInstagram, logoLinkedin, logoFacebook } from 'ionicons/icons';
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default defineComponent({
  name: 'SignUpForm',
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonInput,
    IonButton,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonSpinner
  },
  setup() {
    const states = [
      "Alabama"
      ,"Alaska"
      ,"Arizona"
      ,"Arkansas"
      ,"California"
      ,"Colorado"
      ,"Connecticut"
      ,"Delaware"
      ,"Florida"
      ,"Georgia"
      ,"Hawaii"
      ,"Idaho"
      ,"Illinois"
      ,"Indiana"
      ,"Iowa"
      ,"Kansas"
      ,"Kentucky"
      ,"Louisiana"
      ,"Maine"
      ,"Maryland"
      ,"Massachusetts"
      ,"Michigan"
      ,"Minnesota"
      ,"Mississippi"
      ,"Missouri"
      ,"Montana"
      ,"Nebraska"
      ,"Nevada"
      ,"New Hampshire"
      ,"New Jersey"
      ,"New Mexico"
      ,"New York"
      ,"North Carolina"
      ,"North Dakota"
      ,"Ohio"
      ,"Oklahoma"
      ,"Oregon"
      ,"Pennsylvania"
      ,"Rhode Island"
      ,"South Carolina"
      ,"South Dakota"
      ,"Tennessee"
      ,"Texas"
      ,"Utah"
      ,"Vermont"
      ,"Virginia"
      ,"Washington"
      ,"West Virginia"
      ,"Wisconsin"
      ,"Wyoming"
    ]
    return {
      logoFacebook,
      logoInstagram,
      logoLinkedin,
      states,
      v$: useVuelidate()
    }
  },
  data() {
    return {
      parent: {
        firstName: '',
        lastName: '',
        email: '',
        state: ''
      },
      isLoading: false,
    }
  },
  validations () {
    return {
      parent: {
          firstName: { required },
          lastName: { required },
          email: { required, email },
          state: { required },
      }
    }
  },
  methods: {
    async openToast() {
      const toast = await toastController
        .create({
          header: "Thank you!",
          message: 'We will reach out to you shortly to get your opinons on how the product is being built!.',
          duration: 2000,
          position: "top",
          color: "success"
        })
      return toast.present();
    },
    async postSignup() {
      this.isLoading = true;
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        const response = await fetch("/api/parent-signup-http", {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.parent),
        });
        this.isLoading = false;
        await this.openToast();
        return await response.text();
      }
      this.isLoading = false;
    },
  }
});
