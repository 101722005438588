
import { IonApp, IonRouterOutlet, IonGrid } from '@ionic/vue';
import { defineComponent } from 'vue';

import Header from "@/modules/Layout/Header.vue"

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonGrid,
    Header
  }
});
